@import "text.adoc.less";
@import "text.md.less";

.rendered {
    ol {
        list-style: decimal;
    }

    ul {
        list-style: initial;
    }

    .asciidoc();

    .md {
        .markdown();
    }

    pre,
    code {
        font-family: @codeFont;
        border-radius: 4px;
    }

    pre > code {
        overflow: auto;
    }

    *:not(pre) > code {
        font-size: 0.8em;
        padding: 1px 5px 1px 5px;
        white-space: nowrap;
        background-color: transparent;
        border: 1px solid #dddddd;
        -webkit-border-radius: 3px;
        border-radius: 3px;
        text-shadow: none;
        text-rendering: optimizeSpeed;
        font-weight: normal;
        color: #666666;
        word-wrap: break-word;
    }

    pre:not(".highlight"),
    pre:not(".highlight") > code {
        line-height: 1.6;
        color: #333;
        font-weight: normal;
        position: relative;
        text-rendering: optimizeSpeed
    }

    pre > code.hljs[class*=language]:hover:before {
        text-transform: uppercase;
        font-size: 0.9em;
        color: #999;
        position: absolute;
        top: 0.4em;
        right: 0.4em;
        content: attr(data-language);
    }

    pre.highlight {
        overflow: auto;
    }

    pre {
        margin-bottom: 0.78125rem;
        position: relative;
        padding: 1em;
        border: 1px solid #e1e4e8;

        font-size: 0.75rem;
        line-height: 1.25rem;
    }

    pre.hljs {
        overflow: auto;
    }
}
